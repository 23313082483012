<template>
  <div
    v-if="isShow && data?.alertbar_text"
    class="flex items-center justify-center bg-gradient-to-r from-white to-blue"
  >
    <Container>
      <div class="my-4 flex w-full items-center justify-center">
        <div
          v-if="data?.alertbar_text"
          class="flex-1 text-center font-sans text-xs text-black lg:text-sm"
        >
          {{ data?.alertbar_text }}
        </div>
        <!-- NOTE: Hidden until we are storing the close state in a cooke -->
        <!--<button class="ml-4" @click="isShow = false">
          <CloseIcon />
        </button>-->
      </div>
    </Container>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { Container } from 'refresh-ui'

const props = defineProps({
  data: Object,
})

const isShow = ref(props?.data?.alertbar_active || false)
</script>
